import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { strToTitleCase } from "../../../functions";

const CitiesLinkingBlock = ({}) => {
  const data = useStaticQuery(graphql`
    query averagesQuery {
      allShortTermRentalSetAverages {
        edges {
          node {
            name
            shortTermRentalGroup {
              _id
              _xv
              access
              additional_house_rules
              amenities
              weekly_price_factor
              url
              transit
              total_price
              star_rating
              satisfaction_guest
              room_type
              room_and_property_type
              review_score
              review_count
              response_time
              response_rate
              rating_value
              rating_location
              rating_communication
              rating_cleanliness
              rating_checkin
              rating_accuracy
              price_rate_type
              price_rate
              photos
              person_capacity
              notes
              neighborhood_overview
              name
              monthly_price_factor
              longitude
              interaction
              latitude
              description
              city
              charts
              slug
            }
          }
        }
      }
    }
  `);
  const { allShortTermRentalSetAverages } = data;
  const { edges } = allShortTermRentalSetAverages;
  //console.log("here", data);
  return (
    <div style={{ display: "none" }}>
      {edges.map((edge) => {
        return (
          <>
            <div>
              <a
                href={`/create/invoice/${edge.node.shortTermRentalGroup.slug}`}
              ></a>
              <a
                href={`/free-invoice-template/${edge.node.shortTermRentalGroup.slug}`}
              ></a>
              <a
                href={`/small-business/invoice-template/${edge.node.shortTermRentalGroup.slug}`}
              ></a>

              <a
                href={`/software/invoicing/${edge.node.shortTermRentalGroup.slug}`}
              ></a>

              <a
                href={`/templates/invoice/${edge.node.shortTermRentalGroup.slug}`}
              ></a>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default CitiesLinkingBlock;
