import React from "react";
import InvoiceEmail from "./email-pdf-example.png";
/**
 *
 * @param {heading} string Make this one sentence but add a comma:
 * One click, instant payout
 * @param {description} string Make This Two Sentences:
 * It's that easy. Convert your recurring revenue streams into instant upfront capital anytime.
 * @returns Feature Block With Photo On The Left
 */
export const FeatureBlockPhotoLeft = ({
  heading = "One click, instant payout",
  description = "It's that easy. Convert your recurring revenue streams into instant upfront capital anytime.",
  image,
  backgroundColor,
}) => {
  const descriptions = description.split(".");
  return (
    <div
      id="feature"
      style={{
        backgroundColor,
      }}
      className={`px-8 w-auto`}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 py-36 w-auto md:gap-x-16 items-center">
        {/**
         * Column one
         */}

        <div className="mt-8 md:mt-0">
          <img className="object-contain " src={image || InvoiceEmail} />
        </div>

        {/**
         * Column two
         */}

        <div className="row-start-1 md:row-auto">
          <h1 className="font-bold text-6xl weight text-white">
            {heading.split(",").map((item, i) => {
              if (i === 0)
                return (
                  <>
                    {" "}
                    {item}, <br />{" "}
                  </>
                );
              else return item;
            })}
          </h1>

          <p className="text-3xl pt-8">
            <span className="text-4xl font-extrabold text-white">
              {descriptions[0]}.
            </span>
            <span className="font-bold text-zinc-400">{descriptions[1]}.</span>
          </p>
        </div>
      </div>
    </div>
  );
};
