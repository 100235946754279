import React from "react";
import ButtonPrimary from "./button-primary";
import ButtonOutline from "./button-outline";
import Free from "./assets/Free.png";
import Standard from "./assets/Standard.png";
import Premium from "./assets/Premium.png";
import Amazon from "./assets/Icon/amazon.png";
import Netflix from "./assets/Icon/netflix.png";
import Reddit from "./assets/Icon/reddit.png";
import Discord from "./assets/Icon/discord.png";
import Spotify from "./assets/Icon/spotify.png";
// import TwitterTestimony from "./twitter-testimony";
import TestimonialsCard from "./testimonials";
import { GrProductHunt } from "react-icons/gr";
import { SiNotion } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import TweetOne from "../../../images/tweet-one.png";
import TweetTwo from "../../../images/tweet-two.png";
import { BiPackage } from "react-icons/bi";
const Pricing = () => {
  return (
    <div
      className="bg-gradient-to-b from-white-300 to-white-500 w-full py-14"
      id="pricing"
    >
      <div className="max-w-screen-xl  px-6 sm:px-8 lg:px-16 mx-auto flex flex-col w-full text-center justify-center">
        <div className="flex flex-col w-full">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-black-600 leading-relaxed">
            Use Botion for free or choose a plan!
          </h3>
          <p className="leading-normal w-10/12 sm:w-7/12 lg:w-6/12 mx-auto my-2 text-center">
            Choosing a paid plan will help us improve and launch more financial products for Notion users!
          </p>
          <div className="grid grid-flow-row sm:grid-flow-col grid-cols-1 sm:grid-cols-3 gap-4 lg:gap-12 py-8 lg:py-12 px-6 sm:px-0 lg:px-6">
            <div className="flex flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <BiPackage className="w-10 h-10" />
              </div>
              <p className="text-lg text-black-600 font-medium capitalize my-2 sm:my-7">
                Free Plan
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-black-500 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Invoices
                </li>
                <li className="relative check custom-list my-2">
                  Dashboard
                </li>
                <li className="relative check custom-list my-2">
                  Logs
                </li>
                <li className="relative check custom-list my-2">
                  Email Notifications
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-black-600 text-center mb-4 ">
                  Free
                </p>
                <a href="/register">
                  <ButtonOutline>Select</ButtonOutline>
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <BiPackage className="w-10 h-10" />
              </div>
              <p className="text-lg text-black-600 font-medium capitalize my-2 sm:my-7">
                Monthly Plan{" "}
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-black-500 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Invoices
                </li>
                <li className="relative check custom-list my-2">
                  Dashboard
                </li>
                <li className="relative check custom-list my-2">
                  Logs
                </li>
                <li className="relative check custom-list my-2">
                  Email Notifications
                </li>
                <li className="relative check custom-list my-2">
                  Payment Links
                </li>
                <li className="relative check custom-list my-2">
                  Premium Support
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-black-600 text-center mb-4 ">
                  $9 <span className="text-black-500">/ month</span>
                </p>
                <a href="/register">
                  <ButtonOutline>Select</ButtonOutline>
                </a>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center border-2 border-gray-500 rounded-xl py-4 px-6 lg:px-12 xl:px-20">
              <div className="p-4 lg:p-0 mt-6 lg:mt-16">
                <BiPackage className="w-10 h-10" />
              </div>
              <p className="text-lg text-black-600 font-medium capitalize my-2 sm:my-7">
                Annual Plan{" "}
              </p>
              <ul className="flex flex-col list-inside pl-6 xl:pl-0 items-start justify-start text-left text-black-500 flex-grow">
                <li className="relative check custom-list my-2">
                  Unlimited Invoices
                </li>
                <li className="relative check custom-list my-2">
                  Dashboard
                </li>
                <li className="relative check custom-list my-2">
                  Logs
                </li>
                <li className="relative check custom-list my-2">
                  Email Notifications
                </li>
                <li className="relative check custom-list my-2">
                  Payment Links
                </li>
                <li className="relative check custom-list my-2">
                  Premium Support
                </li>
              </ul>
              <div className="flex flex-col w-full justify-center mb-8 flex-none mt-12">
                <p className="text-2xl text-black-600 text-center mb-4 ">
                  $100 <span className="text-black-500">/ year</span>
                </p>
                <a href="/register">
                  <ButtonOutline>Select</ButtonOutline>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* 
        <div className="flex flex-col w-full my-16">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-black-600 leading-relaxed w-9/12 sm:w-6/12 lg:w-4/12 mx-auto"></h3>
          <p className="leading-normal  mx-auto my-2 w-10/12 sm:w-7/12 lg:w-6/12"></p>
          <div className="py-4 w-full px-8 mt-16"></div>
        </div>
        */}
        <div className="flex flex-col w-full my-16" id="testimoni">
          <h3 className="text-2xl sm:text-3xl lg:text-4xl font-medium text-black-600 leading-normal w-9/12 sm: lg:w-4/12 mx-auto">
            Check out what others are saying!{" "}
          </h3>

          <p className="leading-normal mx-auto mb-2 mt-4 w-10/12 sm:w-7/12 lg:w-6/12">
            Stay tuned for more amazing financial products built by the Botion team!
          </p>

          <div className="w-full flex justify-evenly mt-4 flex-wrap lg:flex-nowrap">
            <img className="object-contain" src={TweetOne} />
            <img className="object-contain" src={TweetTwo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;

/**
 *   <div className="w-full flex justify-evenly items-center mt-4 flex-wrap lg:flex-nowrap">
            <GrProductHunt className="h-14 w-auto mt-4 lg:mt-2" />
            <AiFillTwitterCircle className="h-14 w-auto mt-4 lg:mt-2" />
            <SiNotion className="h-14 w-auto mt-4 lg:mt-2" />
          </div>
 */
