import React from "react";
import InvoicesTable from "./table.png";
/**
 *
 * @param {heading} string Make this one sentence but add a comma:
 * One click, instant payout
 * @param {description} string Make This Two Sentences:
 * It's that easy. Convert your recurring revenue streams into instant upfront capital anytime.
 * @returns Feature Block With Photo On The Right
 */
export const FeatureBlockPhotoRight = ({
  heading = "Invoice Direct from Notion",
  description = "Copy the official template, add customers and start sending invoices! It's that easy!",
  image,
  bgIntensity = 800,
  backgroundColor,
}) => {
  const descriptions = description.split(".");
  // const backgroundColor = `bg-slate-${bgIntensity}`;
  return (
    <div
      id="feature"
      style={{
        backgroundColor,
      }}
      className={`px-8 w-auto`}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 py-36 w-auto items-center">
        {/**
         * Column one
         */}
        <div>
          <h1 className="font-bold text-6xl weight text-white">
            {heading.split(",").map((item, i) => {
              if (i === 0)
                return (
                  <>
                    {" "}
                    {item}, <br />{" "}
                  </>
                );
              else return item;
            })}
          </h1>

          <p className="text-3xl pt-8">
            <span className="text-4xl font-extrabold text-white">
              {descriptions[0]}.
            </span>
            <span className="font-bold text-zinc-400">{descriptions[1]}.</span>
          </p>
        </div>

        {/**
         * Column two
         */}

        <div className="mt-8 md:mt-0">
          <img className="object-contain " src={image || InvoicesTable} />
        </div>
      </div>
    </div>
  );
};
