import React from "react";
import { FaProductHunt } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import Logo from "../../../images/logo.jpeg";
import Logo2 from "../../../images/logo2.png";

const AltFooter = () => {
  return (
    <>
      <div className="grid grid-cols-12 px-4 md:px-40 py-20 " style={{}}>
        <hr className="col-span-full py-4" />
        <div className="col-span-4">
          <a href="/">
            <img src={Logo} className="w-10 h-10" />
          </a>
        </div>
        <div className="col-span-3">
          <div className="text-xl font-bold ">Site</div>
          <div className="mt-10">
            <a href="/" className="text-lg font-medium">
              Home
            </a>
          </div>
          <div className="mt-10">
            <a href="/register" className="text-lg font-medium">
              Register
            </a>
          </div>
          <div className="mt-10">
            <a href="/login" className="text-lg font-medium">
              Log In
            </a>
          </div>
        </div>

        <div className="col-span-3">
          <div className="text-xl font-bold">How To</div>

          <div className="mt-10">
            <a
              href="https://api.botion.so/guide"
              target="_blank"
              className="text-lg font-medium"
            >
              Guide
            </a>
          </div>
          <div className="mt-10">
            <a
              href="https://api.botion.so/template"
              target="_blank"
              className="text-lg font-medium"
            >
              Template
            </a>
          </div>
        </div>

        <div className="row-start-3 md:row-auto mt-16 md:mt-0 md:col-span-2 col-span-full">
          <div className="text-xl font-bold">Social</div>

          <div className="mt-4 flex">
            <a href="https://www.producthunt.com/posts/botion" target="_blank">
              <FaProductHunt className="w-5 h-5" />
            </a>
            <a href={`https://discord.gg/CvudZygqma`} target="_blank">
              <BsDiscord className="ml-8 w-5 h-5" />
            </a>
          </div>
        </div>

        <div className="col-span-full grid grid-cols-12 border-t-2 border-gray-100 h-10 mt-8">
          <div className="col-span-4 mt-4">©2022 Botion</div>

          <div className="flex col-span-8 justify-end mt-4">
            <div>
              <a href="/privacy-policy"> Privacy Policy </a>
            </div>
            <div className="ml-8">
              <a href="/terms"> Terms & Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AltFooter;
